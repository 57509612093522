import React from 'react';

const OnoraLogo = ({ width, height }) => (
  <div dangerouslySetInnerHTML={{ __html: `
    <svg ${height && `height=${height}`} ${width && `width=${width}`}" viewBox="0 0 264 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.5 27C62.5 43.0163 50.9117 66 36 66C21.0883 66 9 53.0163 9 37C9 20.9837 11.0883 43.0001 26 43.0001C40.9117 43.0001 62.5 10.9837 62.5 27Z" fill="#BADA55"/>
    <path d="M71.5921 36.152C71.5921 41.24 70.7281 45.96 69.0001 50.312C67.3041 54.664 64.8881 58.424 61.7521 61.592C58.6481 64.76 54.9041 67.256 50.5201 69.08C46.1361 70.872 41.2721 71.768 35.9281 71.768C30.5841 71.768 25.7201 70.872 21.3361 69.08C16.9521 67.256 13.1921 64.76 10.0561 61.592C6.92006 58.424 4.48806 54.664 2.76006 50.312C1.06406 45.96 0.216064 41.24 0.216064 36.152C0.216064 31.064 1.06406 26.36 2.76006 22.04C4.48806 17.688 6.92006 13.928 10.0561 10.76C13.1921 7.59199 16.9521 5.11199 21.3361 3.31999C25.7201 1.49599 30.5841 0.583984 35.9281 0.583984C41.2721 0.583984 46.1361 1.49599 50.5201 3.31999C54.9041 5.11199 58.6481 7.60799 61.7521 10.808C64.8881 13.976 67.3041 17.736 69.0001 22.088C70.7281 26.408 71.5921 31.096 71.5921 36.152ZM56.8561 36.152C56.8561 32.504 56.3761 29.24 55.4161 26.36C54.4561 23.448 53.0641 20.984 51.2401 18.968C49.4481 16.92 47.2561 15.368 44.6641 14.312C42.1041 13.224 39.1921 12.68 35.9281 12.68C32.6641 12.68 29.7361 13.224 27.1441 14.312C24.5521 15.368 22.3441 16.92 20.5201 18.968C18.7281 20.984 17.3521 23.448 16.3921 26.36C15.4321 29.24 14.9521 32.504 14.9521 36.152C14.9521 39.832 15.4321 43.128 16.3921 46.04C17.3521 48.92 18.7281 51.368 20.5201 53.384C22.3441 55.4 24.5521 56.952 27.1441 58.04C29.7361 59.096 32.6641 59.624 35.9281 59.624C39.1921 59.624 42.1041 59.096 44.6641 58.04C47.2561 56.952 49.4481 55.4 51.2401 53.384C53.0641 51.368 54.4561 48.92 55.4161 46.04C56.3761 43.128 56.8561 39.832 56.8561 36.152Z" fill="#02173B"/>
    <path d="M91.7599 28.272C92.7519 27.312 93.7759 26.432 94.8319 25.632C95.9199 24.832 97.0559 24.144 98.2399 23.568C99.4559 22.992 100.752 22.544 102.128 22.224C103.536 21.904 105.056 21.744 106.688 21.744C109.376 21.744 111.76 22.208 113.84 23.136C115.92 24.064 117.664 25.36 119.072 27.024C120.512 28.688 121.584 30.672 122.288 32.976C123.024 35.28 123.392 37.808 123.392 40.56V72H110.192V40.56C110.192 37.808 109.552 35.68 108.272 34.176C106.992 32.64 105.104 31.872 102.608 31.872C100.752 31.872 99.0079 32.272 97.3759 33.072C95.7439 33.872 94.1919 34.992 92.7199 36.432V72H79.4719V22.512H87.6319C89.2959 22.512 90.3999 23.28 90.9439 24.816L91.7599 28.272Z" fill="#02173B"/>
    <path d="M156.261 21.744C159.973 21.744 163.349 22.336 166.389 23.52C169.429 24.704 172.037 26.4 174.213 28.608C176.389 30.784 178.069 33.44 179.253 36.576C180.437 39.712 181.029 43.248 181.029 47.184C181.029 51.12 180.437 54.672 179.253 57.84C178.069 60.976 176.389 63.648 174.213 65.856C172.037 68.064 169.429 69.76 166.389 70.944C163.349 72.128 159.973 72.72 156.261 72.72C152.517 72.72 149.109 72.128 146.037 70.944C142.997 69.76 140.389 68.064 138.213 65.856C136.037 63.648 134.341 60.976 133.125 57.84C131.941 54.672 131.349 51.12 131.349 47.184C131.349 43.248 131.941 39.712 133.125 36.576C134.341 33.44 136.037 30.784 138.213 28.608C140.389 26.4 142.997 24.704 146.037 23.52C149.109 22.336 152.517 21.744 156.261 21.744ZM156.261 62.784C160.037 62.784 162.837 61.488 164.661 58.896C166.485 56.272 167.397 52.384 167.397 47.232C167.397 42.112 166.485 38.256 164.661 35.664C162.837 33.04 160.037 31.728 156.261 31.728C152.389 31.728 149.541 33.04 147.717 35.664C145.893 38.256 144.981 42.112 144.981 47.232C144.981 52.384 145.893 56.272 147.717 58.896C149.541 61.488 152.389 62.784 156.261 62.784Z" fill="#02173B"/>
    <path d="M201.779 30.672C203.347 27.856 205.155 25.632 207.203 24C209.251 22.368 211.651 21.552 214.403 21.552C216.643 21.552 218.467 22.08 219.875 23.136L219.011 32.928C218.851 33.568 218.595 34.016 218.243 34.272C217.923 34.496 217.475 34.608 216.899 34.608C216.387 34.608 215.651 34.544 214.691 34.416C213.731 34.256 212.835 34.176 212.003 34.176C210.787 34.176 209.699 34.352 208.739 34.704C207.811 35.056 206.979 35.552 206.243 36.192C205.507 36.832 204.835 37.616 204.227 38.544C203.651 39.472 203.107 40.528 202.595 41.712V72H189.347V22.512H197.171C198.515 22.512 199.443 22.752 199.955 23.232C200.467 23.712 200.835 24.544 201.059 25.728L201.779 30.672Z" fill="#02173B"/>
    <path d="M250.844 51.312C247.612 51.472 244.924 51.76 242.78 52.176C240.636 52.56 238.924 53.072 237.644 53.712C236.396 54.352 235.5 55.088 234.956 55.92C234.444 56.72 234.188 57.6 234.188 58.56C234.188 60.48 234.716 61.84 235.772 62.64C236.86 63.44 238.364 63.84 240.284 63.84C242.492 63.84 244.396 63.456 245.996 62.688C247.628 61.888 249.244 60.656 250.844 58.992V51.312ZM223.868 29.376C226.78 26.752 229.996 24.8 233.516 23.52C237.036 22.208 240.844 21.552 244.94 21.552C247.884 21.552 250.508 22.032 252.812 22.992C255.148 23.952 257.116 25.296 258.716 27.024C260.348 28.72 261.596 30.752 262.46 33.12C263.324 35.488 263.756 38.08 263.756 40.896V72H257.708C256.46 72 255.5 71.824 254.828 71.472C254.188 71.12 253.66 70.384 253.244 69.264L252.188 66.096C250.94 67.184 249.724 68.144 248.54 68.976C247.388 69.808 246.188 70.512 244.94 71.088C243.692 71.632 242.348 72.048 240.908 72.336C239.5 72.624 237.916 72.768 236.156 72.768C233.98 72.768 231.996 72.48 230.204 71.904C228.412 71.328 226.86 70.48 225.548 69.36C224.268 68.208 223.276 66.784 222.572 65.088C221.868 63.392 221.516 61.44 221.516 59.232C221.516 57.408 221.98 55.584 222.908 53.76C223.836 51.936 225.42 50.288 227.66 48.816C229.932 47.312 232.94 46.08 236.684 45.12C240.46 44.128 245.18 43.568 250.844 43.44V40.896C250.844 37.792 250.188 35.52 248.876 34.08C247.564 32.608 245.676 31.872 243.212 31.872C241.388 31.872 239.868 32.096 238.652 32.544C237.436 32.96 236.364 33.424 235.436 33.936C234.54 34.416 233.692 34.88 232.892 35.328C232.092 35.744 231.164 35.952 230.108 35.952C229.18 35.952 228.396 35.728 227.756 35.28C227.148 34.8 226.636 34.24 226.22 33.6L223.868 29.376Z" fill="#02173B"/>
    </svg>
  `}} />
)

export default OnoraLogo;