import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './Home.scss';
import { LinkContainer } from 'react-router-bootstrap';

class Home extends React.Component {
  render = () => (
    <div className='Home' id='home'>
      <Container style={{ zIndex: 1, position: "relative" }}>
        <Row className='align-items-center'>
          <Col xs='12' sm='12' md={{ span: 10, offset: 1 }}>
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <h1 style={{ fontSize: '44px', fontWeight: '900' }}>Bema is an investment and incubation platform for early-stage companies.</h1>
            </div>
          </Col>
          <Col xs='12' sm='12' md={{ span: 8, offset: 2 }}>
            <div style={{ textAlign: 'center', marginBottom: '48px', fontSize: '21px' }}>
              <p>We provide seed capital, executive stewardship, day-to-day management, technology development, and operations support to our companies and entrepreneurs.</p>
            </div>
          </Col>
          <Col xs='12' className='text-center' style={{ marginBottom: '120px' }}>
            <LinkContainer to="/why-bema" className='py-3' style={{ borderRadius: "40px", width: "280px" }}>
              <Button size='lg'>
                Why Bema?<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home;