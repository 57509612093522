import React from 'react';
import { Container, Row, Col, Button, Navbar, Nav, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import config from './config';

import './Navigation.scss';
import Logo from '../Assets/Logo';
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Navigation extends React.Component {
  state = {
    isOpen: false,
    showContact: false,
    name: "",
    email: "",
    phone: "",
    message: ""
  }

  toggle = () => (this.setState({ isOpen: !this.state.isOpen }));

  toggleContact = () => (this.setState({ showContact: !this.state.showContact }));

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message
      })
    })
      .then(() => {
        toast.success("Thanks for the submission. We'll be in touch soon!");
        this.setState({
          showContact: false,
          name: "",
          email: "",
          phone: "",
          message: ""
        })
      })
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.45) {
        var id = entry.target.getAttribute('id');
        this.setState({ activeTab: id });
      }
    });
  }

  renderDesktopNavigationElements = () => {
    const { activeTab } = this.state;
    const { location } = this.props;
    console.log(location.pathname);

    return config.map((el, i) => (
      <Col xs='auto' className={i === 0 ? 'ml-auto' : ''} key={`desktop-nav-${i}`}>
        <LinkContainer to={el.link} style={el.link == location.pathname ? { color: "var(--primary)" } : {}}>
          <Button variant='link' >
            {el.label}
          </Button>
        </LinkContainer>
      </Col>
    ));
  }

  renderDesktopNavigation = () => (
    <div className='desktop-nav' id='navbar'>
      <Container>
        <Row noGutters className='align-items-center'>
          <Col xs='3'>
            {/* <img src='/images/bema-logo.svg' alt="BEMA Holdings, LLC" height='21px' /> */}
            <IndexLinkContainer to='/' style={{ cursor: "pointer" }}>
              <div>
                <Logo height={48} />
              </div>
            </IndexLinkContainer>
          </Col>
          {this.renderDesktopNavigationElements()}
          <Col xs='auto'>
            <Button onClick={this.toggleContact} style={{ borderRadius: '24px', borderWidth: '2px', marginLeft: '15px', padding: '8px 24px' }}>
              Contact Us
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )

  renderMobileNavbarElements = () => {
    return config.map((el, i) => (
      <LinkContainer to={el.link}>
        <Nav.Item key={`mobile-el-${i}`}>
          <Nav.Link href={`#${el.id}`}>{el.label}</Nav.Link>
        </Nav.Item>
      </LinkContainer>
    ));
  }

  renderMobileNavigation = () => (
    <div className='mobile-nav'>
      <Navbar light expand="md" collapseOnSelect>
        <IndexLinkContainer to='/'>
          <Navbar.Brand>
            {/* <img src='/images/bema-logo.svg' alt="BEMA Holdings, LLC" height='21px' />  */}
            <div style={{ fontSize: '21px', fontWeight: '500', color: 'var(--primary)' }}>
              <Logo height={48} />
            </div>
          </Navbar.Brand>
        </IndexLinkContainer>
        <Navbar.Toggle onClick={this.toggle} />
        <Navbar.Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto pt-3" navbar>
            {this.renderMobileNavbarElements()}
            <Nav.Item>
              <Nav.Link onClick={this.toggleContact}>Contact</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )

  render = () => {
    const { showContact } = this.state;

    return (
      <>
        <div className='bump'></div>
        <div className='Navigation'>
          {this.renderDesktopNavigation()}
          {this.renderMobileNavigation()}
        </div>
        <Modal show={showContact} onHide={this.toggleContact}>
          <Modal.Header closeButton>
            <Modal.Title>
              Contact Us.
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  size='lg'
                  placeholder="Name" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  size='lg'
                  type="email"
                  placeholder="Email" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  size='lg'
                  placeholder="Phone" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  required
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  as='textarea'
                  rows={4}
                  size='lg'
                  type="email"
                  placeholder="Message" />
              </Form.Group>
              <Button block type="submit" block size='lg' className='mt-3'>Submit</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default withRouter(Navigation);