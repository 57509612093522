const config = [
  {
    label: 'Why Bema?',
    link: "/why-bema"
  },
  {
    label: 'Incubator',
    link: "/incubator"
  },
  {
    label: 'Portfolio',
    link: "/portfolio"
  },
  {
    label: 'Team',
    link: "/team"
  },
]

export default config;