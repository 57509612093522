import * as React from "react";

function SvgResparkLogo(props) {
  return (
    <svg width={728} height={115} fill="none" viewBox="0 0 728 115" {...props}>
      <path
        d="M258.3.1h-29.7v18.8h29.7V.1zM168.9 18.8c-2.2 4.5-3.4 9.4-3.4 14.7 0 9 3.5 17.6 9.9 23.9 6.4 6.4 14.8 9.9 23.8 9.9h.1l42.8-.2c3.7 0 7.2 1.4 9.9 4 2.6 2.6 4.1 6.2 4.1 9.9 0 7.7-6.2 13.9-13.9 13.9h-13.6v18.5h13.6c18.4 0 33.4-13.9 33.4-32.3 0-8.9-3.5-17.3-9.8-23.6-6.3-6.3-14.7-9.5-23.6-9.5h-42.9c-3.8 0-7.4-1.6-10.1-4.3-2.7-2.7-4.2-6.3-4.2-10.2 0-7.9 6.4-14.8 14.3-14.8h-30.4v.1zM288.1 1h44.4c26.4 0 42.8 15 42.8 37.6v.4c0 25.3-20.3 38.4-45 38.4h-22.4v36.2h-19.8V1zm42.8 58.5c15 0 24.3-8.4 24.3-20.1v-.3c0-13.2-9.5-20.1-24.3-20.1h-23v40.5h23zM424.2.2h18.3L492 113.6h-20.9l-11.4-27.2h-53.2l-11.6 27.2h-20.3L424.2.2zm28.3 68.7l-19.5-45-19.3 45h38.8zM511.3 1h50.2c14.2 0 25.3 4.2 32.5 11.3 5.9 6.1 9.3 14.5 9.3 24.3v.3c0 18.5-11.1 29.6-26.7 34.1l30.4 42.6h-23.5l-27.7-39.2H531v39.2h-19.8V1h.1zm48.8 55.8c14.2 0 23.2-7.4 23.2-18.8v-.3c0-12.1-8.7-18.7-23.3-18.7h-28.8v37.8h28.9zM627.6 1h19.8v56.6L700.9 1h24.4l-47 48.4 49.1 64.2h-24.1l-38.4-50.7-17.5 18v32.7h-19.8V1z"
        fill="#FF5B00"
      />
      <path
        d="M0 1h50.2c14.2 0 25.3 4.2 32.5 11.3 6 6.1 9.3 14.5 9.3 24.3v.3C92 55.4 80.9 66.5 65.3 71l30.4 42.6H72.2L44.6 74.3H19.8v39.2H0V1zm48.7 55.8c14.2 0 23.2-7.4 23.2-18.8v-.3c0-12.1-8.7-18.7-23.3-18.7H19.8v37.8h28.9zM154.5 47.8h-47.9v18.9h47.9V47.8zM213.8 95.1H106.6v19.2h107.2V95.1zM213.8.1H106.6v19.2h107.2V.1z"
        fill="#0057D6"
      />
    </svg>
  );
}

export default SvgResparkLogo;
