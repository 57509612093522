import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const WhyBema = () => {

  return (
    <div className='normal-padding'>
      <Container>
        <Row>
          <Col xs='12'>
            <h1>Why Bema?</h1>
            <p style={{ fontSize: '18px', textAlign: 'center', marginBottom: '48px', color: '#555c63' }}>Low risk, cost effective entrepreneurship.</p>
          </Col>
          <Col xs='2' className='d-none d-md-block text-center'>
            <div className='num'>
              1
            </div>
          </Col>
          <Col xs='12' sm='12' md={{ span: 10 }}>
            <h3 className='mb-3'>We’re partners and investors - but most importantly partners.</h3>
            <div className='mb-5'>Our team and advisors have all founded companies and know how to get our hands dirty. It’s not just skills and experience, but the attitude behind those skills and experience that matters. We listen to and respect the decision making of our entrepreneurs.</div>
          </Col>
          <Col xs='2' className='d-none d-md-block text-center'>
            <div className='num-gray'>
              2
            </div>
          </Col>
          <Col xs='12' sm='12' md={{ span: 10 }}>
            <h3 className='mb-3'>We’re not constrained by the fund economics of venture capital.</h3>
            <div className='mb-5'>The members of Bema’s management team are the largest investors in their existing portfolio companies in addition to being the operating partner. Without a fund or outside investors, Bema incubated companies can follow the growth trajectories that are best for the founders, employees, and the business.</div>
          </Col>
          <Col xs='2' className='d-none d-md-block text-center'>
            <div className='num-primary'>
              3
            </div>
          </Col>
          <Col xs='12' sm='12' md={{ span: 10 }}>
            <h3 className='mb-3'>We’ve changed the risk profile and barriers to becoming an entrepreneur.</h3>
            <div className='mb-5'>Our BaseApp changes the economics of starting a technology company. Whether a recent graduate or a professional with a family, Bema can incubate an idea in a short time frame with minimal capital requirements and the supporting technology and innovation services to improve the chances of success.</div>
          </Col>
          <Col xs='12' className='mb-5 text-center mt-3'>
            <LinkContainer to="/incubator" className='py-3' style={{ borderRadius: "40px", width: "280px" }}>
              <Button size='lg'>
                Incubation Process<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyBema;