import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import './Footer.scss';

class Footer extends React.Component {
  render = () => {
    const { location } = this.props;

    if (location.pathname == '/') {
      return null;
    }

    return (
      <div className='Footer'>
        <Container>
          <Row>
            <Col xs='12' className='text-muted'>
              Copyright {'\u00A9'} 2020 Bema Technologies, LLC. All rights reserved.
          </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withRouter(Footer);