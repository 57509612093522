import React from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';

const NotFound = () => (
  <div className='pt-5' style={{ minHeight: "100vh" }}>
    <Container>
      <Row>
        <Col xs='12'>
          <Alert variant='dark'>
            <h4>Page Not Found</h4>
            <p>We can't find the page you're looking for. You can either return to the previous page, visit our homepage, or contact our support team.</p>
          </Alert>
          <div className='text-right'>
            <IndexLinkContainer to='/'>
              <Button>
                Take Me Home
                    </Button>
            </IndexLinkContainer>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default NotFound;