import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import './Companies.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import SvgResparkLogo from './ResparkLogo';
import SvgLutumLogo from './LutumLogo';
import SvgOnoraLogo from './OnoraLogo';
import SvgCollegelabLogo from './CollegelabLogo';
import RealCISOLogo from './RealCISOLogo';
import RadarLogo from './RadarLogo';

const companies = [
  {
    title: 'ReSpark',
    href: 'https://respark.insure',
    logo: <SvgResparkLogo width="100%" height="100%" />,
    description: "ReSpark is an independent, market agnostic insurance technology that facilitates the complete management of an insurance program."
  },
  {
    title: 'Lutum Technologies',
    href: 'https://www.lutumtechnologies.com/',
    logo: <SvgLutumLogo width="100%" height="100%" />,
    description: "Lutum is a development platform for molecularly imprinted polymer sensors. Lutum works with partner companies to identify specific target molecules of interest, develop sensors for them, and help engineer the sensors for delivery in a commercial application."
  },
  {
    title: 'CollegeLab',
    href: 'https://www.collegelab.org',
    logo: <SvgCollegelabLogo width="100%" height="100%" />,
    description: <Trans i18nKey="collegeLabDescription" />
  },
  // {
  //   title: 'Radar',
  //   href: '',
  //   logo: <RadarLogo width="100%" height="100%" />,
  //   description: <Trans i18nKey="radarDescription" />
  // },
]

const projects = [
  {
    title: 'RealCISO',
    href: 'https://realciso.io',
    logo: <RealCISOLogo width="100%" height="100%" />,
    description: "RealCISO is an an easy-to-use guided solution for any company to understand and manage cyber risk."
  },
  {
    title: 'Onora Global',
    href: 'https://onoraglobal.com',
    logo: <SvgOnoraLogo width="100%" height="100%" />,
    description: "Onora makes it easy and possible for millions around the world to do their part in stopping climate change."
  },
]

class Companies extends React.Component {
  renderCompanies(config) {
    return config.map((c, i) => (
      <Col xs='12' key={`company-${i}`} className='company'>
        <div className='border shadow-sm rounded'>
          <Row className='align-items-center'>
            <Col xs='12' sm='12' md='3' style={{ textAlign: 'center' }}>
              {c.href && <a href={c.href} rel='noopener noreferrer' target='_blank' className='logo py-3 pl-md-5'>
                {/* <div style={{
                backgroundImage: `url(${c.logo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '100%',
                width: '100%'
              }} /> */}
                {c.logo}
              </a>}
              {!c.href && <div className='logo py-3 pl-md-5'>{c.logo}</div>}
            </Col>
            <Col xs='12' sm='12' md='9'>
              <div className='company-card'>
                <h4 style={{ fontSize: '21px', fontWeight: 'bold', marginBottom: '0.75rem', color: 'var(--gray-dark)' }}>{c.title}</h4>
                <div style={{ marginBottom: '0.75rem' }}>{c.description}</div>
                {c.href && <div style={{ textAlign: 'right' }}>
                  <a href={c.href} rel='noopener noreferrer' target='_blank'>Learn More <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </div>}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    ));
  }

  render = () => (
    <div className='Companies normal-padding' id='companies'>
      <Container>
        <Row>
          <Col xs='12' style={{ textAlign: 'center' }}>
            <h1 className='mb-4'>Our Companies.</h1>
          </Col>
          {this.renderCompanies(companies)}
          <Col xs='12' style={{ textAlign: 'center' }}>
            <h1 className='mb-4 mt-4'>Our Projects.</h1>
          </Col>
          {this.renderCompanies(projects)}
          <Col xs='12' className='mb-5 text-center mt-3'>
            <LinkContainer to="/team" className='py-3' style={{ borderRadius: "40px", width: "280px" }}>
              <Button size='lg'>
                Our Team<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Companies