import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import './Contact.scss';

class Contact extends React.Component {
  render = () => (
    <div className='Contact' id='contact'>
      <Container>
        <Row>
          <Col xs='12' style={{ textAlign: 'center' }}>
            {/* <h1 style={{ marginBottom: '36px' }}>Contact</h1> */}
            <h6 style={{ fontWeight: 'normal' }}>10 Buck Rd, Hanover, NH 03755</h6>
            <h5 style={{ marginBottom: '48px', color: '#106a41' }}>
              <a style={{ color: '#106a41' }} href='mailto:info@bema.dev'>info@bema.dev</a>
            </h5>
            <div style={{ fontSize: '14px', color: '#555c63' }}>Copyright © 2020 BEMA Technologies, LLC. All rights reserved.</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact;