import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

import './IncubationProcess.scss';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const IncubationProcess = () => (
  <div className='IncubationProcess normal-padding'>
    <Container id='our-process'>
      <Row>
        <Col xs='12' className='text-center'>
          <h1 className='mb-2'>Incubator Program.</h1>
          <p className='mb-4' style={{ fontSize: '18px' }}>Get your idea to market.</p>
        </Col>
        <Col xs='12' sm='12' md='6'>
          <div className='border shadow-sm rounded px-3 bg-white'>
            <Row>
              <Col xs='12' sm='12' md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h1 className='pt-3'>Incubation Model.</h1>
                <p style={{ fontSize: '18px', textAlign: 'center', marginBottom: '64px', color: '#555c63' }}>Co-found a company with Bema.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num'>1</div>
                <p style={{ fontSize: "smaller" }}>Identify opportunities based on alternate applications of our existing technologies in new markets.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num'>2</div>
                <p style={{ fontSize: "smaller" }}>Evaluate market opportunity, capital needs, potential partners.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num'>3</div>
                <p style={{ fontSize: "smaller" }}>Identify dedicated entrepreneur and operating partner in target industry. Determine go-to-market strategy. We will hold IP until the right partners can be found.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num'>4</div>
                <p style={{ fontSize: "smaller" }}>Finalize investment and resource allocation decision by our 5-member investment committee with deep experience in start-ups and venture capital.</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs='12' sm='12' md='6'>
          <div className='border shadow-sm rounded px-3 bg-white'>
            <Row>
              <Col xs='12'>
                <h1 className='pt-3'>Acceleration Model.</h1>
                <p style={{ fontSize: '18px', textAlign: 'center', marginBottom: '64px', color: '#555c63' }}>Transform your existing ideas or products.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num-primary'>1</div>
                <p style={{ fontSize: "smaller" }}>Identify opportunities based on alternate applications of our existing technologies in new markets.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num-primary'>2</div>
                <p style={{ fontSize: "smaller" }}>Evaluate market opportunity, capital needs, potential partners.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num-primary'>3</div>
                <p style={{ fontSize: "smaller" }}>Identify dedicated entrepreneur and operating partner in target industry. Determine go-to-market strategy. We will hold IP until the right partners can be found.</p>
              </Col>
              <Col xs='12' sm='12' md='6' style={{ paddingBottom: '48px' }} className='text-center'>
                <div className='num-primary'>4</div>
                <p style={{ fontSize: "smaller" }}>Finalize investment and resource allocation decision by our 5-member investment committee with deep experience in start-ups and venture capital.</p>
              </Col>
            </Row>
          </div>
        </Col>


        <Col xs='12' className='text-center mb-5 mt-3'>
          <h1 className='pt-3 pb-3'>Our Process.</h1>
          <div>
            <img src='/example-bema-tech.png' className='img-fluid' />
          </div>
        </Col>
        <Col xs='12' className='mb-5 text-center mt-3'>
          <LinkContainer to="/portfolio" className='py-3' style={{ borderRadius: "40px", width: "280px" }}>
            <Button size='lg'>
              Our Portfolio<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  </div>
);

export default IncubationProcess;