import React, { useEffect } from 'react';

// translations
import './i18n';

import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';

import Home from './pages/Home/Home';
// import Companies from './pages/Companies/Companies';
import Team from './pages/Team/Team';
import Contact from './pages/Contact/Contact';
import IncubationProcess from './pages/IncubationProcess/IncubationProcess';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import NotFound from './pages/NotFound';
import Companies from './pages/Companies/Companies';
import WhyBema from './pages/WhyBema';

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
}

const App = () => {
  return (
    <Router>
      <Navigation />

      <Switch>
        <Route exact path="/">
          <div>
            <Home />
            <Contact />
          </div>
        </Route>
        <Route exact path="/why-bema">
          <WhyBema />
        </Route>
        <Route exact path="/portfolio">
          <Companies />
        </Route>
        <Route exact path="/incubator">
          <IncubationProcess />
        </Route>
        <Route exact path="/team">
          <Team />
        </Route>

        <Route path="*">
          <div>
            <NotFound />
          </div>
        </Route>
      </Switch>
      <Footer />
      <ScrollToTop />

      <ToastContainer
        className='toast-container'
        position="bottom-right"
        autoClose={10000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
