import React from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';

import './Team.scss';

const config = [
  {
    name: 'Aimahd Sabky',
    title: 'Partner Kerflummox Capital, co-founder Bema Holdings - management and operations',
    description: 'Aimahd is the chief operating officer for Kerflummox and Bema companies and is responsible for product development and operations. Aimahd is a co-founder at CollegeLab and CyberBuoy. Before Kerflummox Capital, Aimahd worked at MakeSpace. Aimahd graduated from Amherst College in 2014 with a degree in Mathematics and from The Middlesex School in Concord, MA in 2010.'
  },
  {
    name: 'Jamal Sabky',
    title: 'Partner Kerflummox Capital, co-founder Bema Holdings – executive management, business development',
    description: 'Jamal is a serial entrepreneur, and co-founder of CollegeLab, an algorithm-enabled college admissions service, PreventAGEHealth Care, a diabetes diagnostic firm; CyberBuoy, a cyber insurance and response service; Kings Row Coffee, a cold brew coffee company, and Lutum Technology, a molecularly-imprinted polymer sensor development company focused on industrial, security, and health-care applications. Jamal received his undergraduate degree from Dartmouth College.'
  },
  {
    name: 'George Faux',
    title: 'Founder Lone Pine Ventures, co-founder Bema Holdings – executive management, business development',
    description: <div><p>George is a Senior Partner, International Advisory Partners, a firm that counsels public and private entities on economic, commercial and infrastructure developments; a co-founder of CollegeLab, an algorithm-enabled college admissions service, and a co-founder Lutum Technology, a molecularly-imprinted polymer sensor development company focused on industrial, security, and health-care applications.</p>
      <p>Previously, George was an executive at Fortent, a joint venture with Warburg Pincus, and spent 18 years managing many of Citi Group's business globally. George received his undergraduate degree from Dartmouth College and a master's degree from the Johhs Hopkins School of Advanced International Studies.</p></div>
  },
  {
    name: 'Don Stephan',
    title: 'Partner Bema Technologies - lead developer, manager technical operations',
    description: 'Don is the Technical Operations Manager and Lead developer of for Kerflummox Capital\'s companies where he manages all application and development operations. He is also the co-founder of Spatial Cinematics, a mixed-reality company in Austin, TX. Don received an undergraduate degree from Dartmouth College.'
  },
  {
    name: 'S. Caroline Kerr',
    title: 'Partner Kerflummox Capital - corporate strategy and business development',
    description: 'Caroline is a builder, connector, and cultivator, who has led organizations through significant phases of growth and innovation. Previously, she served as CEO of the Joyce Ivy Foundation, an organization that broadens college aspirations and leadership development of talented young women, and has deep expertise in the college admissions and education space. She builds and advises organizations on strategy, growth, and governance. Caroline serves on multiple boards, including Dartmouth, Nelson A. Rockefeller Center for Public Policy and Social Sciences, and CollegeLab. Caroline received her undergraduate degree from Dartmouth College and a master\'s degree from Harvard.'
  },
  {
    name: 'Christian Davies',
    title: 'Director of innovation and insurance',
    description: 'Christian is a highly regarded innovator and technologist in the cyber, media, and technology insurance world. Most recently, Christian lead innovation and insurance at CyberBuoy, a personal cyber insurance start-up. Previously, Christian set up and ran the Technology, Media, and Cyber Insurance practice at EC3 Brokers, a Lloyd\'s of London broker, where he placed complex insurances for some of the largest and fastest growing companies in technology over the last 7 years. Christian has worked with companies all over the globe.'
  },
  {
    name: 'Chris Goulet',
    title: '',
    description: ''
  },
  {
    name: 'Brant Sanborn',
    title: '',
    description: ''
  },
  {
    name: 'Todd White',
    title: '',
    description: ''
  },
  {
    name: 'John Ruth',
    title: '',
    description: ''
  },
  {
    name: 'Abbie Montgomery',
    title: '',
    description: ''
  },
  {
    name: 'Sharon Ellman',
    title: '',
    description: ''
  },
]

const LinkedInButton = ({ url }) => (
  <div style={{ display: 'inline-block', marginLeft: '8px' }} className='linkedin'>
    <a href={url} target='_blank' rel='noopener noreferrer'>
      <img src='/linkedin.png' height={20} />
    </a>
  </div>
)

class TeamMember extends React.Component {
  state = {
    isModalOpen: false
  }

  toggle = () => (this.setState({ isModalOpen: !this.state.isModalOpen }));

  renderModal() {
    const { isModalOpen } = this.state;
    const { e } = this.props;

    return (
      <Modal show={isModalOpen} hide={this.toggle} size='lg'>
        <Modal.Header toggle={this.toggle}>{e.name}</Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: '18px', marginBottom: '1rem' }}>{e.title}</div>
          <div style={{ paddingBottom: '0.5rem' }}>{e.description}</div>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    const { e } = this.props;

    return (
      // <div className='team-member'>
      //   <div className="flip-container" onClick={() => {this.classList.toggle('hover')}}>
      //     <div className="flipper">
      //       <div className="front">
      //         <div className='name'>{e.name}</div>
      //         <div className='title'>{e.title}</div>
      //       </div>
      //       <div className="back">
      //         <div style={{ paddingBottom: '0.5rem' }}>{e.description}</div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div className='team-member' onClick={this.toggle}>
        <div className='name'>{e.name}</div>
        <div className='title'>{e.title}</div>
        {this.renderModal()}
      </div>
    )
  }
}

class Team extends React.Component {
  renderTeam = () => {
    let length = config.length;

    return config.map((e, i) => (
      <Col xs='12' sm='12' md={length % 3 === 1 && i >= length - 4 ? '6' : '4'} key={`team-${i}`}>
        <TeamMember e={e} />
      </Col>
    ));
  }

  render = () => (
    <div className='Team normal-padding' id='team'>
      <Container>
        <Row>
          <Col xs='12' sm='12' md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h1>Our Team.</h1>
            <p className='mb-4' style={{ fontSize: '18px', textAlign: 'center', color: '#555c63' }}>This is the team that will bring your company to life.</p>
          </Col>
          <Col xs='12' className='text-left'>
            <h2 className='mb-3'><u>Management.</u></h2>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>George Faux</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Co-Founder / Director</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Senior Partner, International Advisory Partners, a firm that counsels public and private entities on economic, commercial and infrastructure developments</li>
                <li>Co-founder, Director of CollegeLab, an algorithm and data driven college counseling application</li>
                <li>Co-founder, Director, Lutum Technology, a development company for polymer sensors</li>
                <li>Former executive at Fortent, a fintech joint venture with Warburg Pincus</li>
                <li>Former Managing Director, Citigroup</li>
                <li>AB Dartmouth College, MA Johns Hopkins University.</li>
              </ul>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Jamal Sabky</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Co-Founder / Director</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Co-Founder, CEO CollegeLab</li>
                <li>Co-Founder, CEO Lutum Technology</li>
                <li>Managing Member, Kerflummox Capital, a family investment and management company</li>
                <li>Co-Founder, Director Kings Row Coffee and Kings Cold Brew,</li>
                <li>Co-Founder, PreventAGE Health Care, a diagnostic for diabetic complications</li>
                <li>AB Dartmouth College</li>
              </ul>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Aimahd Sabky</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Co-Founder / COO</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Co-founder, COO CollegeLab</li>
                <li>COO, Kerflummox Capital</li>
                <li>Former operations, MakeSpace</li>
                <li>BA Amherst College</li>
              </ul>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>S. Caroline Kerr</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Partner</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Director, CollegeLab</li>
                <li>Director, and former CEO Joyce Ivy Foundation</li>
                <li>Trustee, Dartmouth College</li>
                <li>Board Member, Nelson A. Rockefeller Center for Public Policy and Social Sciences</li>
                <li>AB Dartmouth College, EdM Harvard University</li>
              </ul>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Rob Richer</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Senior Advisor</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Senior Partner, International Advisory Partners</li>
                <li>CEO and President, Uptima Group</li>
                <li>Former Associate Deputy Director of Operations (ADDO) at Central Intelligence Agency (retired 2005)</li>
                <li>Former Chief of Near East and South Asia division, responsible for Clandestine Service Operations through Middle East and South Asia</li>
              </ul>
            </div>
          </Col>
          <Col xs='12' className='text-left'>
            <h2 className='mb-3 mt-4'><u>Platform.</u></h2>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Kristen Carey</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Director of Platform</h5>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Nick Rockwell</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Director of Partnerships</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Currently Managing Director of Growth @ CircleUp</li>
                <li>Formerly US Head of Sales &amp; Partnerships @ Funding Circle US (FCH LSE)</li>
                <li>Formerly Vice President of Equity Sales @ Canaccord Genuity (CF TSX)</li>
                <li>BA Economics &amp; English Hamilton College</li>
              </ul>
            </div>
          </Col>
          <Col xs='12' className='text-left'>
            <h2 className='mt-4 mb-3'><u>Engineering.</u></h2>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Don Stephan<LinkedInButton url='https://www.linkedin.com/in/donald-stephan-855ab587/' /></h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Co-Founder / Lead Engineer</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Former Lead Developer, Kerflummox Capital</li>
                <li>Co-Founder, Spatial Cinematics</li>
                <li>AB Dartmouth College</li>
              </ul>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Omer Trajman</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Technology Advisor</h5>
              <ul style={{ color: '#555c63' }}>
                <li>Founder, Askfora</li>
                <li>Former CEO Rocana</li>
                <li>Former VP Technology Solutions, Cloudera</li>
                <li>Board Member, Tufts Entrepreneurship Center</li>
                <li>B.Sc Tufts</li>
              </ul>
            </div>
          </Col>
          {/* <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Chris Goulet</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Engineer</h5>
            </div>
          </Col> */}
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Brant Sanborn</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Engineer</h5>
            </div>
          </Col>
          <Col xs='12' className='text-left'>
            <h2 className='mb-3 mt-4'><u>Legal and Operations.</u></h2>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Ryan Gardner</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>General Counsel</h5>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Elizabeth Roberts</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>HR Advisor</h5>
            </div>
          </Col>
          <Col xs='12' className='text-left'>
            <h2 className='mb-3 mt-4'><u>Investment Committee/Advisors.</u></h2>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Stuart Ellman</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Co-Founder and General Partner of RRE Ventures</h5>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Bill Wyman</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Co-Founder of Oliver Wyman &amp; Co</h5>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Ben Hemani</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>Venture Capital at BMGI</h5>
            </div>
          </Col>
          <Col xs='12' sm='12' md='6' className='mb-3'>
            <div className='border mt-2 px-3 pt-3 rounded shadow-sm bg-white' style={{ height: "100%" }}>
              <div className='top-bar-dec' />
              <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Gordie Nye</h3>
              <h5 style={{ fontWeight: 'normal', textAlign: 'left', marginBottom: '16px' }}>PE/VC CEO</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Team;